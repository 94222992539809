.breadcrumbs {

     li {
          @include container-flex;
          align-items: center;

          &::after {
               content: '';
               width: 6px;
               height: 6px;
               margin: 0 $gutter-3xs;
               background: $primary;
               border-radius: 50%;
          }

          &:last-child {

               &::after {
                    display: none;
               }

               a {
                    color: $primary;
                    font-weight: $font-weight-strong;
                    text-decoration: none;
               }
          }
     }

     a {
          color: inherit;
          text-decoration: underline;

          &:hover,
          &:focus,
          &:active {
               color: $secondary;
          }
     }
}