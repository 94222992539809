.single-meta {

     .meta-item {
          display: flex;
          margin: 0 $gutter-m $gutter-3xs 0;
          font-size: $font-size-xs;
          color: $neutral;

          i.bi {
               margin-right: $gutter-3xs;
               color: $primary;
               font-size: $font-size-l;
               line-height: 1;
          }
     }
}