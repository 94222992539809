.header {

    position: relative;

     @include md-max {
          @include container-flex;
          flex-direction: column;

          .header-bottom {
               order: -1;
          }
     }

     .header-top {
          overflow: hidden;

          .logo-krs {

               img {
                    flex: 0 1 0;
                    margin-right: $gutter-s;
               }

               h1 {
                    flex: 1 0 0;
                    font-size: $font-size-xl;
               }
          }

          .actions {
               text-align: right;

               &.has-lang-en {

                    .lang-bg {
                         display: none;
                    }
               }

               &.has-lang-bg {

                    .lang-en {
                         display: none;
                    }
               }

               .actions-box {
                    position: relative;
                    padding-top: $gutter-m;
                    padding-bottom: $gutter-m;
                    padding-left: $gutter-l;
                    background: $primary;
                    border-radius: $gutter-s 0 0 $gutter-s;

                    &::after {
                         content: '';
                         position: absolute;
                         top: 0;
                         bottom: 0;
                         right: -2000%;
                         width: 2000%;
                         height: auto;
                         background: $primary;
                    }

                    a {
                         @include container-flex-center;
                         width: $gutter-3xl;
                         height: $gutter-3xl;
                         padding: $gutter-2xs;
                         color: $white;
                         border: 3px solid;
                         border-radius: 50%;
                         font-size: $font-size-3xl;
                         line-height: 1;
                    }

                    .language {
                         margin-left: $gutter-xs;
                    }
               }
          }
     }

     .header-bottom {
          // background: $secondary;
          background: $gray;
     }
}

.mobile-search-form{
    display: none;
    width: 100%;
    padding: 10px;
    position: absolute;
    top: 50px;
    background: #c8ced4;
}

.exit-search-ms{
    display: none;
}

@media (max-width: #{$screen-sm-max}) {
    .header.active-ms{

        .mobile-search-form{
            display: block;
        }
        .mobile-search-trigger{
            .bi-search{
                display: none;
            }
            .exit-search-ms{
                display: block;
            }
        }
    }
}

@media (max-width: #{$screen-xs-max}) {
    .header {
        .header-top {
            .logo-krs {

                img {
                    flex: 0 1 0;
                    margin-right: $gutter-s;
                }

                h1 {
                    flex: 1 0 0;
                    font-size: $font-size-s;
                }
            }
        }
    }
}
