.poll-item{
    margin-bottom: 20px;
    padding: 20px;
    border: 2px solid #f8f8f8;
    border-radius: 5px;

    h3{
        position: relative;
        i{
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}



.poll_question_container{

    margin-bottom: 30px;

    h3{
        font-size: 22px;
    }
    .poll_options_container{

        margin-right: 0;
        margin-left: 0;


        .poll_options_group{

            h4{
                font-size: 16px;
            }

        }

        .question-error-message{
            margin-top: 0.25rem;
            font-size: 80%;
            color: #e74a3b;
        }

    }
}
.poll_question_container.question_has_error{
    h3{
        color:#e74a3b;
    }
}
