.links-list,
.files-list {
    margin: 0;
    margin-bottom: 1.5rem;
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

        .title {
            font-size: 1.25rem;
        }

        i {
            margin-right: 0.5rem;
            line-height: 1;
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}
