.blog-post {
     @include container-flex;
     flex-direction: column;
     height: 100%;

     .image {
          flex: 0 0 auto;
     }

     .content {
          flex: 1 0 auto;

          &:not(:last-child) {
               margin-bottom: $gutter-l;
          }
     }

     .title {
          font-weight: $base-font-weight;
          font-size: $font-size-l;
          line-height: 1.5;

          &:not(:last-child) {
               margin-bottom: $gutter-xs;
          }

          a {
               text-decoration: none;
          }
     }

     .meta {
          flex-shrink: 0;
          @include container-flex;
          align-items: center;
          justify-content: space-between;
          padding-top: $gutter-xs;
          border-top: 1px dashed $black-20;
          font-size: $font-size-xs;

          .date {
               margin-right: $gutter-xs;
               color: $neutral;
          }

          .link-more {
               @include container-flex;
               align-items: center;

               i.bi {
                    font-size: $font-size-s;
                    margin-left: $gutter-4xs;
               }
          }
     }
}