.header-navigation {
     padding: 0;

     @include md-min-max {
          justify-content: flex-end;
     }

     @include md-max {
          .actions {

               .lang-en,
               .lang-bg {
                    display: block;
               }

               &.has-lang-en {

                    .lang-bg {
                         display: none;
                    }
               }

               &.has-lang-bg {

                    .lang-en {
                         display: none;
                    }
               }

               .actions-box {
                    padding-top: $gutter-3xs;
                    padding-bottom: $gutter-3xs;

                    .search {
                         color: $white;
                         font-size: $font-size-xl;
                         line-height: 1;
                    }

                    .language {
                         width: $gutter-l;
                         margin-left: $gutter-xs;
                         border: 1px solid $white;
                    }
               }
          }
     }

     .navbar-toggler {
          padding: $gutter-3xs 0;
          color: $white;
          font-size: $font-size-3xl;
          border: none;

          &:not(.collapsed) {

               .closed {
                    display: none;
               }
          }

          &.collapsed {

               .opened {
                    display: none;
               }
          }
     }

     @include lg-min {

          .navbar-collapse,
          .navbar-nav {
               flex-basis: 100%;
          }

          .dropdown-menu {
               width: 100%;
               top: 100%;
               left: 0;
               right: 0;
          }
     }

     .nav-item.static {
          flex-basis: auto;
          flex-grow: 1;
          max-width: 100%;

          @include lg-min {
               text-align: center;
          }

          @include md-max {
               &+.nav-item {
                    border-top: 1px dashed $white-40;
               }
          }

          @media (min-width: 992px) and (max-width: 1279px) {
               flex-basis: 0;
               flex-grow: 1;
               max-width: 100%;
          }

          &.dropdown {

               @include lg-min {
                    position: static;
               }

               @include md-max {
                    // &::after {
                    //     content: '';
                    //      position: absolute;
                    //      top: $gutter-s;
                    //      width: 20px;
                    //      height: 20px;
                    //      right: $gutter-column;
                    //      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2ZmZiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY0NiA0LjY0NmEuNS41IDAgMCAxIC43MDggMEw4IDEwLjI5M2w1LjY0Ni01LjY0N2EuNS41IDAgMCAxIC43MDguNzA4bC02IDZhLjUuNSAwIDAgMS0uNzA4IDBsLTYtNmEuNS41IDAgMCAxIDAtLjcwOHoiLz4KPC9zdmc+");
                    //      background-size: cover;
                    //      cursor: pointer;
                    // }

                    .mm-trig{
                         position: absolute;
                         top: 1rem;
                         width: 27px;
                         height: 27px;
                         right: $gutter-column;
                         background-size: cover;
                         cursor: pointer;
                    }
               }
          }

          >.nav-link {
               padding: $gutter-xs;
               // color: $white;
               color: $black;
               font-size: $font-size-m;

               @include lg-min {
                    @include container-flex-center;
                    height: 100%;
               }

               @include md-max {
                    display: inline-block;
                    padding: $gutter-xs $gutter-column;
               }

               &:hover,
               &:focus,
               &:active,
               &.active {
                    @include lg-min {
                         background: $white;
                         color: $primary;
                         @include shadow;
                    }

                    @include md-max {
                         text-decoration: underline;
                    }
               }
          }
     }

     .dropdown-menu {
          margin: 0;
          padding: $gutter-4xs 0;
          border: none;
          border-radius: 0;

          @include lg-min {
               @include shadow-soft;
          }

          .has-bigger-gutters {

               @include lg-min {
                    padding-left: $gutter-m;
                    padding-right: $gutter-m;
               }
          }

          .nav-item {

               >.nav-link {
                    padding: $gutter-2xs 0;
                    color: $black;
                    font-size: $font-size-m;

                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                         color: $primary;
                    }

                    &.active {
                         font-weight: $font-weight-medium;
                    }
               }

               // &+.nav-item {
               &.nav-item {
                    // border-top: 1px dashed $black-20;
                    border-bottom: 1px dashed $black-20;
               }
          }
     }
}

@media (min-width: #{$screen-lg-min}) {
    .header-navigation .dropdown:hover .dropdown-menu,
    .header-navigation .dropdown .dropdown-menu:hover {
         display: block !important;
    }
}

.header-navigation .dropdown.active-dropdown .dropdown-menu{
    display: block !important;
}


// search
#search_container{
    position: relative;
}
#search_container i{
    position: absolute;
    font-size: 32px;
    left: 20px;
    top: 10px;
    color:#fff;
}
#search{
    width: 14.8rem;
    height: 4rem;
    border-radius: 40px;
    border-style: none;
    background: transparent;
    border: 3px solid #fff;

    color:#fff;
    padding-left: 60px;
}
#search:focus{
    border: 3px solid #c8ced4;
    outline: none;
}
#search::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}
