// Colors
$white: #fff;
$white-10: rgba($white, 0.1);
$white-20: rgba($white, 0.2);
$white-30: rgba($white, 0.3);
$white-40: rgba($white, 0.4);
$white-50: rgba($white, 0.5);
$white-60: rgba($white, 0.6);
$white-70: rgba($white, 0.7);
$white-80: rgba($white, 0.8);
$white-90: rgba($white, 0.9);

$black: #000;
$black-10: rgba($black, 0.1);
$black-20: rgba($black, 0.2);
$black-30: rgba($black, 0.3);
$black-40: rgba($black, 0.4);
$black-50: rgba($black, 0.5);
$black-60: rgba($black, 0.6);
$black-70: rgba($black, 0.7);
$black-80: rgba($black, 0.8);
$black-90: rgba($black, 0.9);

$primary: #135EA9;
$primary-10: rgba($primary, 0.1);
$primary-20: rgba($primary, 0.2);
$primary-30: rgba($primary, 0.3);
$primary-40: rgba($primary, 0.4);
$primary-50: rgba($primary, 0.5);
$primary-60: rgba($primary, 0.6);
$primary-70: rgba($primary, 0.7);
$primary-80: rgba($primary, 0.8);
$primary-90: rgba($primary, 0.9);


// $secondary: #D91C24;
$secondary: #606060;
$secondary-10: rgba($secondary, 0.1);
$secondary-20: rgba($secondary, 0.2);
$secondary-30: rgba($secondary, 0.3);
$secondary-40: rgba($secondary, 0.4);
$secondary-50: rgba($secondary, 0.5);
$secondary-60: rgba($secondary, 0.6);
$secondary-70: rgba($secondary, 0.7);
$secondary-80: rgba($secondary, 0.8);
$secondary-90: rgba($secondary, 0.9);

$gray : #c8ced4;
$gray-10: rgba($gray, 0.1);
$gray-20: rgba($gray, 0.2);
$gray-30: rgba($gray, 0.3);
$gray-40: rgba($gray, 0.4);
$gray-50: rgba($gray, 0.5);
$gray-60: rgba($gray, 0.6);
$gray-70: rgba($gray, 0.7);
$gray-80: rgba($gray, 0.8);
$gray-90: rgba($gray, 0.9);

$red : #D91C24;
$red-10: rgba($red, 0.1);
$red-20: rgba($red, 0.2);
$red-30: rgba($red, 0.3);
$red-40: rgba($red, 0.4);
$red-50: rgba($red, 0.5);
$red-60: rgba($red, 0.6);
$red-70: rgba($red, 0.7);
$red-80: rgba($red, 0.8);
$red-90: rgba($red, 0.9);


$neutral: #686868;
$off-white: #ebebeb;


// Fonts
$font-weight-light: 300;
$base-font-weight: 400;
$font-weight-medium: 500;
$font-weight-strong: 700;
$primary-font-family: 'Fira Sans BGR', sans-serif;

$font-size-2xs: 0.75rem; // 12px
$font-size-xs:  0.875rem; // 14px
$font-size-s:   1rem; // 16px - Base
$font-size-m:   1.125rem; // 18px
$font-size-l:   1.25rem; // 20px
$font-size-xl:  1.5rem; // 24px
$font-size-2xl: 1.75rem; // 28px
$font-size-3xl: 2rem; // 32px
$font-size-4xl: 2.25rem; // 36px



// Gutters
$gutter-4xs:  0.25rem;
$gutter-3xs:   0.5rem;
$gutter-2xs:  0.75rem;
$gutter-xs:      1rem;
$gutter-s:    1.25rem;
$gutter-m:     1.5rem;
$gutter-l:       2rem;
$gutter-xl:    2.5rem;
$gutter-2xl:     3rem;
$gutter-3xl:     4rem;
$gutter-4xl:     5rem;
$gutter-row:    -15px;
$gutter-column:  15px;
