.search-results-information {
     font-weight: $font-weight-light;
     font-size: $font-size-l;

     .count,
     .keyword {
          color: $primary;
          font-weight: $font-weight-medium;
     }
}

.search-results {

     .search-result {
          margin-top: $gutter-l;

          .title {
               font-size: $font-size-xl;
          }

          .meta {
               @include container-flex;
               align-items: center;
               margin-top: $gutter-xs;
               padding-top: $gutter-xs;
               border-top: 1px dashed $black-20;
               font-size: $font-size-xs;

               .date {
                    margin-right: $gutter-xs;
                    color: $neutral;

                    .last-update {
                         color: $black;
                         font-weight: bolder;
                    }
               }
          }

          &.file {

               .title {

                    a {
                         display: flex;

                         i.bi {
                              margin-right: $gutter-3xs;
                              line-height: 1;
                         }
                    }
               }
          }
     }
}