html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  color: #000;
  font-family: "Fira Sans BGR", sans-serif;
}

.container-fluid {
  max-width: 1440px;
}

a {
  color: #135EA9;
}
a:hover, a:active, a:focus {
  color: #606060;
  outline: 0;
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

iframe,
embed,
object,
video,
audio,
canvas {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

button {
  cursor: pointer;
}
button:focus {
  outline: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
blockquote:last-child,
figure:last-child,
hr:last-child {
  margin-bottom: 0;
}

figure figcaption {
  margin-top: 0.5rem;
}

blockquote {
  padding: 1rem 0 1rem 1.5rem;
  border-left: 0.25rem solid #135EA9;
  font-size: 1.125rem;
}

i,
q,
cite,
em,
address {
  font-style: italic;
}

mark {
  padding: 0;
  background: #606060;
  color: #fff;
}

.entry-content ul ul,
.entry-content ul ol,
.entry-content ol ul,
.entry-content ol ol {
  margin-top: 0.25rem;
}
.entry-content ul li + li,
.entry-content ol li + li {
  margin-top: 0.25rem;
}

.section-heading-primary {
  padding-top: 2rem;
  margin-bottom: 1rem;
  border-top: 2px solid;
  color: #606060;
}

.section-heading-secondary {
  margin-bottom: 1rem;
  color: #135EA9;
}

.unordered-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
}
.unordered-list.icon-link li {
  display: flex;
}
.unordered-list.icon-link li::before {
  content: "\f471";
  color: #135EA9;
  font-size: 1.25rem;
  font-family: "bootstrap-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.5rem;
}
.unordered-list.icon-link li a {
  color: inherit;
}
.unordered-list.icon-link li a:hover, .unordered-list.icon-link li a:focus, .unordered-list.icon-link li a:active {
  color: #606060;
}
.unordered-list li + li {
  margin-top: 0.5rem;
}

.ratio {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.ratio.ratio-16-9 {
  padding-bottom: 56.25%;
}
.ratio.ratio-4-3 {
  padding-bottom: 75%;
}
.ratio.ratio-3-2 {
  padding-bottom: 66.6666666667%;
}
.ratio img,
.ratio video {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
}

.btn {
  padding: 0.5rem 1rem;
}
.btn.has-full-width {
  width: 100%;
}

.btn-krs-primary {
  color: #fff;
  background-color: #135EA9;
  border-color: #135EA9;
}
.btn-krs-primary:hover, .btn-krs-primary:focus, .btn-krs-primary:active {
  color: #fff;
  background-color: #0f4b87;
  border-color: #0e457b;
}
.btn-krs-primary:focus {
  box-shadow: 0 0 0 3px rgba(19, 94, 169, 0.5);
}

.btn-krs-secondary {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
}
.btn-krs-secondary:hover, .btn-krs-secondary:focus, .btn-krs-secondary:active {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #474747;
}
.btn-krs-secondary:focus {
  box-shadow: 0 0 0 3px rgba(96, 96, 96, 0.5);
}

.btn-krs-red {
  color: #fff;
  background-color: #D91C24;
  border-color: #D91C24;
}
.btn-krs-red:hover, .btn-krs-red:focus, .btn-krs-red:active {
  color: #fff;
  background-color: #b7181e;
  border-color: #ac161d;
}
.btn-krs-red:focus {
  box-shadow: 0 0 0 3px rgba(217, 28, 36, 0.5);
}

.header-navigation {
  padding: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-navigation {
    justify-content: flex-end;
  }
}
@media (max-width: 991px) {
  .header-navigation .actions .lang-en,
.header-navigation .actions .lang-bg {
    display: block;
  }
  .header-navigation .actions.has-lang-en .lang-bg {
    display: none;
  }
  .header-navigation .actions.has-lang-bg .lang-en {
    display: none;
  }
  .header-navigation .actions .actions-box {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .header-navigation .actions .actions-box .search {
    color: #fff;
    font-size: 1.5rem;
    line-height: 1;
  }
  .header-navigation .actions .actions-box .language {
    width: 2rem;
    margin-left: 1rem;
    border: 1px solid #fff;
  }
}
.header-navigation .navbar-toggler {
  padding: 0.5rem 0;
  color: #fff;
  font-size: 2rem;
  border: none;
}
.header-navigation .navbar-toggler:not(.collapsed) .closed {
  display: none;
}
.header-navigation .navbar-toggler.collapsed .opened {
  display: none;
}
@media (min-width: 992px) {
  .header-navigation .navbar-collapse,
.header-navigation .navbar-nav {
    flex-basis: 100%;
  }
  .header-navigation .dropdown-menu {
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
  }
}
.header-navigation .nav-item.static {
  flex-basis: auto;
  flex-grow: 1;
  max-width: 100%;
}
@media (min-width: 992px) {
  .header-navigation .nav-item.static {
    text-align: center;
  }
}
@media (max-width: 991px) {
  .header-navigation .nav-item.static + .nav-item {
    border-top: 1px dashed rgba(255, 255, 255, 0.4);
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .header-navigation .nav-item.static {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .header-navigation .nav-item.static.dropdown {
    position: static;
  }
}
@media (max-width: 991px) {
  .header-navigation .nav-item.static.dropdown .mm-trig {
    position: absolute;
    top: 1rem;
    width: 27px;
    height: 27px;
    right: 15px;
    background-size: cover;
    cursor: pointer;
  }
}
.header-navigation .nav-item.static > .nav-link {
  padding: 1rem;
  color: #000;
  font-size: 1.125rem;
}
@media (min-width: 992px) {
  .header-navigation .nav-item.static > .nav-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
@media (max-width: 991px) {
  .header-navigation .nav-item.static > .nav-link {
    display: inline-block;
    padding: 1rem 15px;
  }
}
@media (min-width: 992px) {
  .header-navigation .nav-item.static > .nav-link:hover, .header-navigation .nav-item.static > .nav-link:focus, .header-navigation .nav-item.static > .nav-link:active, .header-navigation .nav-item.static > .nav-link.active {
    background: #fff;
    color: #135EA9;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 991px) {
  .header-navigation .nav-item.static > .nav-link:hover, .header-navigation .nav-item.static > .nav-link:focus, .header-navigation .nav-item.static > .nav-link:active, .header-navigation .nav-item.static > .nav-link.active {
    text-decoration: underline;
  }
}
.header-navigation .dropdown-menu {
  margin: 0;
  padding: 0.25rem 0;
  border: none;
  border-radius: 0;
}
@media (min-width: 992px) {
  .header-navigation .dropdown-menu {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 992px) {
  .header-navigation .dropdown-menu .has-bigger-gutters {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.header-navigation .dropdown-menu .nav-item > .nav-link {
  padding: 0.75rem 0;
  color: #000;
  font-size: 1.125rem;
}
.header-navigation .dropdown-menu .nav-item > .nav-link:hover, .header-navigation .dropdown-menu .nav-item > .nav-link:focus, .header-navigation .dropdown-menu .nav-item > .nav-link:active, .header-navigation .dropdown-menu .nav-item > .nav-link.active {
  color: #135EA9;
}
.header-navigation .dropdown-menu .nav-item > .nav-link.active {
  font-weight: 500;
}
.header-navigation .dropdown-menu .nav-item.nav-item {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}

@media (min-width: 992px) {
  .header-navigation .dropdown:hover .dropdown-menu,
.header-navigation .dropdown .dropdown-menu:hover {
    display: block !important;
  }
}
.header-navigation .dropdown.active-dropdown .dropdown-menu {
  display: block !important;
}

#search_container {
  position: relative;
}

#search_container i {
  position: absolute;
  font-size: 32px;
  left: 20px;
  top: 10px;
  color: #fff;
}

#search {
  width: 14.8rem;
  height: 4rem;
  border-radius: 40px;
  border-style: none;
  background: transparent;
  border: 3px solid #fff;
  color: #fff;
  padding-left: 60px;
}

#search:focus {
  border: 3px solid #c8ced4;
  outline: none;
}

#search::placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.form .label {
  cursor: pointer;
}
.form .label:hover {
  color: #135EA9;
}
.form .label .required-field {
  color: #606060;
}
.form .input,
.form .select,
.form .textarea {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  color: #686868;
  font-family: inherit;
  font-size: 1rem;
  box-shadow: none;
  outline: none;
  appearance: none;
  text-overflow: ellipsis;
}
.form .input::placeholder,
.form .select::placeholder,
.form .textarea::placeholder {
  color: #686868;
  opacity: 1;
}
.form .input:focus,
.form .select:focus,
.form .textarea:focus {
  border-color: #135EA9;
  color: #000;
}
.form .input.file,
.form .select.file,
.form .textarea.file {
  cursor: pointer;
}
.form .input.file:focus,
.form .select.file:focus,
.form .textarea.file:focus {
  border-color: rgba(0, 0, 0, 0.1);
  color: #000;
}
.form .textarea {
  min-height: 5rem;
  height: 10rem;
}

.pagination .page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.25rem;
  padding: 0.25rem;
  color: #000;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.pagination .page:hover {
  color: #135EA9;
  box-shadow: 0 0 0 1px #135EA9;
  text-decoration: none;
}
.pagination .page.current-page {
  background: #135EA9;
  color: #fff;
  box-shadow: 0 0 0 1px #135EA9;
}
.pagination .page.prev, .pagination .page.next {
  font-size: 1.25rem;
}

.single-meta .meta-item {
  display: flex;
  margin: 0 1.5rem 0.5rem 0;
  font-size: 0.875rem;
  color: #686868;
}
.single-meta .meta-item i.bi {
  margin-right: 0.5rem;
  color: #135EA9;
  font-size: 1.25rem;
  line-height: 1;
}

.swiper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 575px) {
  .hero-slider .swiper-navigation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
.hero-slider .swiper-navigation .swiper-button-prev,
.hero-slider .swiper-navigation .swiper-button-next {
  width: auto;
  height: auto;
  margin: 0;
  color: #fff;
  font-size: 2.25rem;
  line-height: 1;
  transform: translateY(-50%);
}
.hero-slider .swiper-navigation .swiper-button-prev::before, .hero-slider .swiper-navigation .swiper-button-prev::after,
.hero-slider .swiper-navigation .swiper-button-next::before,
.hero-slider .swiper-navigation .swiper-button-next::after {
  display: none;
}
.hero-slider .swiper-navigation .swiper-button-prev:hover,
.hero-slider .swiper-navigation .swiper-button-next:hover {
  color: #606060;
}
@media (max-width: 575px) {
  .hero-slider .swiper-navigation .swiper-button-prev,
.hero-slider .swiper-navigation .swiper-button-next {
    position: static;
    margin: 1rem 0.5rem 0;
    color: #606060;
    transform: none;
  }
  .hero-slider .swiper-navigation .swiper-button-prev:hover,
.hero-slider .swiper-navigation .swiper-button-next:hover {
    color: #135EA9;
  }
}
.hero-slider .swiper-navigation .swiper-button-prev {
  left: 1.25rem;
}
.hero-slider .swiper-navigation .swiper-button-next {
  right: 1.25rem;
}

.opdu-slider .swiper-pagination {
  position: static;
  margin: 1rem auto 0;
  transform: none !important;
}

.breadcrumbs li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.breadcrumbs li::after {
  content: "";
  width: 6px;
  height: 6px;
  margin: 0 0.5rem;
  background: #135EA9;
  border-radius: 50%;
}
.breadcrumbs li:last-child::after {
  display: none;
}
.breadcrumbs li:last-child a {
  color: #135EA9;
  font-weight: 700;
  text-decoration: none;
}
.breadcrumbs a {
  color: inherit;
  text-decoration: underline;
}
.breadcrumbs a:hover, .breadcrumbs a:focus, .breadcrumbs a:active {
  color: #606060;
}

.cookie-notification {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ebebeb;
}
.cookie-notification .content {
  font-weight: 300;
  font-size: 0.875rem;
}
.cookie-notification .content b,
.cookie-notification .content strong {
  font-weight: 700;
}

.accordion {
  margin-top: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.accordion .accordion-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.accordion .accordion-item .accordion-item-title {
  padding: 1.5rem 1rem;
  position: relative;
  cursor: pointer;
}
.accordion .accordion-item .accordion-item-title h3 {
  margin: 0;
  padding-right: 1.5rem;
  font-size: 1.25rem;
}
.accordion .accordion-item .accordion-item-title i {
  color: #135EA9;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  transition: all 300ms;
}
.accordion .accordion-item .accordion-item-title:hover {
  color: #135EA9;
}
.accordion .accordion-item .accordion-item-content {
  padding: 1rem;
}
.accordion .accordion-item:nth-child(2n) {
  background: rgba(0, 0, 0, 0.03);
}
.accordion .accordion-item:last-child {
  border-bottom: 0;
}
.accordion .accordion-item .accordion-text {
  margin-bottom: 2rem;
}
.accordion .accordion-item.active {
  border: 1px solid #135EA9;
}
.accordion .accordion-item.active .accordion-item-title i {
  transform: rotate(180deg);
}

.zoom-website-text {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  height: 70px;
}
.zoom-website-text .zoom-row {
  width: 30px;
  height: 30px;
  line-height: 25px;
  text-align: center;
  border: 1px solid #135EA9;
  border-radius: 3px;
  cursor: pointer;
  background: #fff;
  user-select: none;
}
.zoom-website-text .zoom-row img {
  width: 18px;
}
.zoom-website-text .zoom-row:first-child {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .zoom-website-text {
    display: none;
  }
}
.header {
  position: relative;
}
@media (max-width: 991px) {
  .header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .header .header-bottom {
    order: -1;
  }
}
.header .header-top {
  overflow: hidden;
}
.header .header-top .logo-krs img {
  flex: 0 1 0;
  margin-right: 1.25rem;
}
.header .header-top .logo-krs h1 {
  flex: 1 0 0;
  font-size: 1.5rem;
}
.header .header-top .actions {
  text-align: right;
}
.header .header-top .actions.has-lang-en .lang-bg {
  display: none;
}
.header .header-top .actions.has-lang-bg .lang-en {
  display: none;
}
.header .header-top .actions .actions-box {
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  background: #135EA9;
  border-radius: 1.25rem 0 0 1.25rem;
}
.header .header-top .actions .actions-box::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2000%;
  width: 2000%;
  height: auto;
  background: #135EA9;
}
.header .header-top .actions .actions-box a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  padding: 0.75rem;
  color: #fff;
  border: 3px solid;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 1;
}
.header .header-top .actions .actions-box .language {
  margin-left: 1rem;
}
.header .header-bottom {
  background: #c8ced4;
}

.mobile-search-form {
  display: none;
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 50px;
  background: #c8ced4;
}

.exit-search-ms {
  display: none;
}

@media (max-width: 767px) {
  .header.active-ms .mobile-search-form {
    display: block;
  }
  .header.active-ms .mobile-search-trigger .bi-search {
    display: none;
  }
  .header.active-ms .mobile-search-trigger .exit-search-ms {
    display: block;
  }
}
@media (max-width: 575px) {
  .header .header-top .logo-krs img {
    flex: 0 1 0;
    margin-right: 1.25rem;
  }
  .header .header-top .logo-krs h1 {
    flex: 1 0 0;
    font-size: 1rem;
  }
}
.footer {
  background: rgba(0, 0, 0, 0.03);
}
.footer .unordered-list {
  margin-bottom: 0.5rem;
}
.footer .footer-bottom {
  background: rgba(0, 0, 0, 0.04);
}
.footer .copyright {
  font-size: 0.875rem;
}

.blog-post {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
}
.blog-post .image {
  flex: 0 0 auto;
}
.blog-post .content {
  flex: 1 0 auto;
}
.blog-post .content:not(:last-child) {
  margin-bottom: 2rem;
}
.blog-post .title {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
}
.blog-post .title:not(:last-child) {
  margin-bottom: 1rem;
}
.blog-post .title a {
  text-decoration: none;
}
.blog-post .meta {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  font-size: 0.875rem;
}
.blog-post .meta .date {
  margin-right: 1rem;
  color: #686868;
}
.blog-post .meta .link-more {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.blog-post .meta .link-more i.bi {
  font-size: 1rem;
  margin-left: 0.25rem;
}

.search-results-information {
  font-weight: 300;
  font-size: 1.25rem;
}
.search-results-information .count,
.search-results-information .keyword {
  color: #135EA9;
  font-weight: 500;
}

.search-results .search-result {
  margin-top: 2rem;
}
.search-results .search-result .title {
  font-size: 1.5rem;
}
.search-results .search-result .meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  font-size: 0.875rem;
}
.search-results .search-result .meta .date {
  margin-right: 1rem;
  color: #686868;
}
.search-results .search-result .meta .date .last-update {
  color: #000;
  font-weight: bolder;
}
.search-results .search-result.file .title a {
  display: flex;
}
.search-results .search-result.file .title a i.bi {
  margin-right: 0.5rem;
  line-height: 1;
}

.links-list,
.files-list {
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  list-style: none;
}
.links-list li,
.files-list li {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}
.links-list li .title,
.files-list li .title {
  font-size: 1.25rem;
}
.links-list li i,
.files-list li i {
  margin-right: 0.5rem;
  line-height: 1;
}
.links-list li:last-child,
.files-list li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.hero-section img {
  display: block;
  width: 100%;
}
@media (min-width: 576px) {
  .hero-section .caption {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.hero-section .caption a {
  display: block;
  padding: 1.25rem 1.5rem;
  background: rgba(200, 206, 212, 0.8);
  color: #000;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
.hero-section .useful-links li + li {
  margin-top: 0.69rem;
}
.hero-section .useful-links a {
  display: block;
  padding: 1.25rem 1.5rem;
  border: 1px solid;
}
.hero-section .useful-links .nav-item a {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
}
.hero-section .useful-links .nav-item.has-color-bg a {
  background-color: #135EA9;
  border-color: #135EA9;
  color: #fff;
}

.useful-section .opdu-container {
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.useful-section .partners-list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  margin: 0;
  text-align: center;
}
.useful-section .partners-list li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.useful-section .partners-list li:first-child, .useful-section .partners-list li:nth-last-child(2) {
  grid-column-start: 1;
  grid-column-end: 3;
}
.useful-section .partners-list a {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 1rem;
  color: inherit;
}
.useful-section .partners-list a:hover, .useful-section .partners-list a:focus, .useful-section .partners-list a:active {
  background: rgba(0, 0, 0, 0.03);
  text-decoration: none;
}
.useful-section .partners-list a .image,
.useful-section .partners-list a .partner {
  width: 100%;
}

.homepage-button {
  background-color: #135EA9;
  border-color: #135EA9;
  color: #fff;
  display: block;
  padding: 1.25rem 1.5rem;
  border: 1px solid;
}
.homepage-button:hover {
  color: white;
}

.excerpt {
  position: relative;
  max-height: 240px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  line-height: 1.5;
  max-height: 15;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.excerpt * {
  font-size: 1rem !important;
}

.excerpt:after {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  content: "...";
}

.contacts-container {
  margin-bottom: 2rem;
}

.contacts-list {
  border-top: 1px solid #135EA9;
}

.contacts-list-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}

.contacts-info {
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.map {
  height: 400px;
}
.map iframe {
  width: 100%;
  height: 100%;
}

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-control-custom.is-invalid ~ .invalid-feedback, .form-control-custom.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-control-custom:invalid ~ .invalid-feedback, .was-validated .form-control-custom:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-select.is-invalid, .form-control-custom.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control-custom:invalid {
  border-color: #dc3545;
}

.poll-item {
  margin-bottom: 20px;
  padding: 20px;
  border: 2px solid #f8f8f8;
  border-radius: 5px;
}
.poll-item h3 {
  position: relative;
}
.poll-item h3 i {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.poll_question_container {
  margin-bottom: 30px;
}
.poll_question_container h3 {
  font-size: 22px;
}
.poll_question_container .poll_options_container {
  margin-right: 0;
  margin-left: 0;
}
.poll_question_container .poll_options_container .poll_options_group h4 {
  font-size: 16px;
}
.poll_question_container .poll_options_container .question-error-message {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e74a3b;
}

.poll_question_container.question_has_error h3 {
  color: #e74a3b;
}