.btn {
     padding: $gutter-3xs $gutter-xs;

     &.has-full-width {
          width: 100%;
     }
}

.btn-krs-primary {
     color: $white;
     background-color: $primary;
     border-color: $primary;

     &:hover,
     &:focus,
     &:active {
          color: $white;
          background-color: darken($primary, 7.5%);
          border-color: darken($primary, 10%);
     }

     &:focus {
          box-shadow: 0 0 0 3px rgba($primary, 0.5);
     }
}

.btn-krs-secondary {
     color: $white;
     background-color: $secondary;
     border-color: $secondary;

     &:hover,
     &:focus,
     &:active {
          color: $white;
          background-color: darken($secondary, 7.5%);
          border-color: darken($secondary, 10%);
     }

     &:focus {
          box-shadow: 0 0 0 3px rgba($secondary, 0.5);
     }
}

.btn-krs-red {
     color: $white;
     background-color: $red;
     border-color: $red;

     &:hover,
     &:focus,
     &:active {
          color: $white;
          background-color: darken($red, 7.5%);
          border-color: darken($red, 10%);
     }

     &:focus {
          box-shadow: 0 0 0 3px rgba($red, 0.5);
     }
}
