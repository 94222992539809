html {
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    color: $black;
    font-family: $primary-font-family;
}

.container-fluid {
    max-width: 1440px;
}

@include xl-min {
    .container {
        // max-width: 990px;
    }
}

a {
    color: $primary;

    &:hover,
    &:active,
    &:focus {
        color: $secondary;
        outline: 0;
        text-decoration: underline;
    }
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

svg:not(:root) {
    overflow: hidden;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

iframe,
embed,
object,
video,
audio,
canvas {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

button {
    cursor: pointer;

    &:focus {
        outline: 0;
    }
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
blockquote:last-child,
figure:last-child,
hr:last-child {
    margin-bottom: 0;
}

figure {

    figcaption {
        margin-top: $gutter-3xs;
    }
}

blockquote {
    padding: $gutter-xs 0 $gutter-xs $gutter-m;
    border-left: $gutter-4xs solid $primary;
    font-size: $font-size-m;
}

i,
q,
cite,
em,
address {
    font-style: italic;
}

mark {
    padding: 0;
    background: $secondary;
    color: $white;
}

.entry-content {

    ul,
    ol {

        ul,
        ol {
            margin-top: $gutter-4xs;
        }

        li+li {
            margin-top: $gutter-4xs;
        }
    }
}

.section-heading-primary {
    padding-top: $gutter-l;
    margin-bottom: $gutter-xs;
    border-top: 2px solid;
    color: $secondary;
}

.section-heading-secondary {
    margin-bottom: $gutter-xs;
    color: $primary;
}

.unordered-list {
    @include container-flex;
    flex-direction: column;
    @include no-list-style;
    padding-left: 0;

    &.icon-link {

        li {
            display: flex;

            &::before {
                content: '\f471';
                color: $primary;
                font-size: $font-size-l;
                font-family: "bootstrap-icons";
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin-right: $gutter-3xs;
            }

            a {
                color: inherit;

                &:hover,
                &:focus,
                &:active {
                    color: $secondary;
                }
            }
        }
    }

    li+li {
        margin-top: $gutter-3xs;
    }
}

.ratio {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;

    &.ratio-16-9 {
        padding-bottom: calc((9/16) * 100%);
    }

    &.ratio-4-3 {
        padding-bottom: calc((3/4) * 100%);
    }

    &.ratio-3-2 {
        padding-bottom: calc((2/3) * 100%);
    }

    img,
    video {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-position: center center;
        object-fit: cover;
    }
}
