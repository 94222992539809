.pagination {
    
     %pagination-styles {
          @include container-flex-center;
          width: $gutter-xl;
          height: $gutter-xl;
          margin: $gutter-4xs;
          padding: $gutter-4xs;
          color: $black;
          box-shadow: 0 0 0 1px $black-10;
     }

     .page {
          @extend %pagination-styles;

          &:hover {
               color: $primary;
               box-shadow: 0 0 0 1px $primary;
               text-decoration: none;
          }   

          &.current-page {
               background: $primary;
               color: $white;
               box-shadow: 0 0 0 1px $primary;
          }

          &.prev,
          &.next {
               font-size: $font-size-l;
          }
     }
}