.cookie-notification {
     z-index: 10;
     position: fixed;
     bottom: 0;
     left: 0;
     right: 0;
     background: $off-white;

     .content {
          font-weight: $font-weight-light;
          font-size: $font-size-xs;

          b,
          strong {
               font-weight: $font-weight-strong;
          }
     }
}