.swiper {
     position: relative;
     width: 100%;
     height: 100%;
     overflow: hidden;
}

.hero-slider {

     .swiper-navigation {

          @include xs-max {
               @include container-flex-center;
          }

          .swiper-button-prev,
          .swiper-button-next {
               width: auto;
               height: auto;
               margin: 0;
               color: $white;
               font-size: $font-size-4xl;
               line-height: 1;
               transform: translateY(-50%);

               &::before,
               &::after {
                    display: none;
               }

               &:hover {
                    color: $secondary;
               }

               @include xs-max {
                    position: static;
                    margin: $gutter-xs $gutter-3xs 0;
                    color: $secondary;
                    transform: none;

                    &:hover {
                         color: $primary;
                    }
               }
          }

          .swiper-button-prev {
               left: $gutter-s;
          }

          .swiper-button-next {
               right: $gutter-s;
          }
     }
}

.opdu-slider {

     .swiper-pagination {
          position: static;
          margin: $gutter-xs auto 0;
          transform: none !important;
     }
}