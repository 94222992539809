@mixin container-flex {
     display: flex;
     flex-wrap: wrap;
}

@mixin container-flex-center {
     @include container-flex;
     align-items: center;
     justify-content: center;
}

@mixin container-inline-flex {
     display: inline-flex;
     flex-wrap: wrap;
}

@mixin no-list-style {
     list-style: none;
}

@mixin hidden {
     opacity: 0;
     visibility: hidden;
     pointer-events: none;
}

@mixin visible {
     opacity: 1;
     visibility: visible;
     pointer-events: all;
}

@mixin shadow-soft {
     box-shadow: 0 0 $gutter-3xs $black-10;
}

@mixin shadow {
     box-shadow: 0 0 $gutter-xs $black-10;
}

@mixin shadow-strong {
     box-shadow: 0 0 $gutter-m $black-10;
}