.hero-section {

     img {
          display: block;
          width: 100%;
     }

     .caption {

          @include sm-min {
               z-index: 2;
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
          }

          a {
               display: block;
               padding: $gutter-s $gutter-m;
               // background: $black-80;
               background: $gray-80;
               // color: $white;
               color: $black;
               font-weight: $base-font-weight;
               font-size: $font-size-s;
               line-height: 1.5;
          }
     }

     .useful-links {

          li+li {
               // margin-top: $gutter-2xs;
               margin-top: 0.69rem;
          }

          a {
               display: block;
               padding: $gutter-s $gutter-m;
               border: 1px solid;
          }

          .nav-item {

               a {
                    background-color: $white;
                    border-color: $black-20;
               }
          }

          .nav-item.has-color-bg {

               a {
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;
               }
          }
     }
}
