.form {

     .label {
          cursor: pointer;

          &:hover {
               color: $primary;
          }

          .required-field {
               color: $secondary;
          }
     }

     .input,
     .select,
     .textarea {
          display: block;
          width: 100%;
          margin-bottom: $gutter-xs;
          padding: $gutter-2xs $gutter-xs;
          background: $white;
          border: 2px solid $black-10;
          color: $neutral;
          font-family: inherit;
          font-size: $font-size-s;
          box-shadow: none;
          outline: none;
          appearance: none;
          text-overflow: ellipsis;

          &::placeholder {
               color: $neutral;
               opacity: 1;
          }

          &:focus {
               border-color: $primary;
               color: $black;
          }

          &.file {
               cursor: pointer;

               &:focus {
                    border-color: $black-10;
                    color: $black;
               }
          }
     }

     .textarea {
          min-height: $gutter-4xl;
          height: $gutter-4xl * 2;
     }
}