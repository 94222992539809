.footer {
     background: rgba($black, 0.03);

     .unordered-list {
          margin-bottom: $gutter-3xs;
     }

     .footer-bottom {
          background: rgba($black, 0.04);
     }

     .copyright {
          font-size: $font-size-xs;
     }
}