body{
    // position: relative;
}


.zoom-website-text{
    position: fixed;
    top: 0;
    bottom:0;
    right: 10px;
    margin: auto;

    height: 70px;

    .zoom-row{
        width: 30px;
        height: 30px;
        line-height: 25px;
        text-align: center;
        border: 1px solid #135EA9;
        border-radius: 3px;
        cursor: pointer;
        background: #fff;
        user-select: none;

        img{
            width: 18px;
        }
        &:first-child{
            margin-bottom: 10px;
        }
    }
}


@media (max-width: #{$screen-sm-max}) {
    .zoom-website-text{
        display: none;
    }
}
