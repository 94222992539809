
.excerpt{
    position: relative;
    max-height: 240px;

    word-break: break-word;
    overflow: hidden;
    // text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.5;
    max-height: 15;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;

    display: -webkit-box;
    overflow: hidden;
}
.excerpt *{
    font-size: 1rem !important;
}
// .dots {
//     display: inline-block;
//     position: relative;
// }
//
// .dots:after {
//     display: inline-block;
//     position: absolute;
//     right: 0;
//     bottom: 0;
//     content: '...';
// }
.excerpt:after {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    content: '...';
}
