.useful-section {

     .opdu-container {
          padding: $gutter-xs;
          border: 1px solid $black-10;
     }

     .partners-list {
          @include no-list-style;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          padding: 0;
          margin: 0;
          text-align: center;

          li {
               @include container-flex-center;
               height: 100%;
               border: 1px solid $black-10;

               &:first-child,
               &:nth-last-child(2) {
                    grid-column-start: 1;
                    grid-column-end: 3;
               }
          }

          a {
               @include container-flex;
               align-items: flex-end;
               width: 100%;
               height: 100%;
               padding: $gutter-xs;
               color: inherit;

               &:hover,
               &:focus,
               &:active {
                    background: rgba($black, 0.03);
                    text-decoration: none;
               }

               .image,
               .partner {
                    width: 100%;
               }
          }
     }
}


.homepage-button{
    background-color: $primary;
    border-color: $primary;
    color: $white;
    // width: 80%;

    display: block;
    padding: 1.25rem 1.5rem;
    border: 1px solid;

    &:hover{
        color:white;
    }

}
