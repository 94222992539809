.accordion {
    margin-top: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .accordion-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .accordion-item-title {
            padding: 1.5rem 1rem;
            position: relative;
            cursor: pointer;

            h3 {
                margin: 0;
                padding-right: 1.5rem;
                font-size: 1.25rem;
            }

            i {
                color: $primary;
                position: absolute;
                top: 1.5rem;
                right: 1rem;
                transition: all 300ms;
            }

            &:hover {
                color: $primary;
            }
        }

        .accordion-item-content {
            padding: 1rem;
            // display: none;
        }

        &:nth-child(2n) {
            background: rgba(0, 0, 0, 0.03);
        }

        &:last-child {
            border-bottom: 0;
        }

        .accordion-text {
            margin-bottom: 2rem;
        }

        &.active {
            border: 1px solid $primary;

            .accordion-item-title {

                i {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
