.contacts-container {
    margin-bottom: 2rem;
}
.contacts-list {
    border-top: 1px solid $primary;
}
.contacts-list-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}
.contacts-info {
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.map {
    height: 400px;

    iframe {
        width: 100%;
        height: 100%;
    }
}

// Custom class to use bootstrap show/hide form errors ( change bootstrap class from to : [form-control -> form-control-custom ])
.custom-select.is-invalid~.invalid-feedback, .custom-select.is-invalid~.invalid-tooltip, .form-control-custom.is-invalid~.invalid-feedback, .form-control-custom.is-invalid~.invalid-tooltip, .was-validated .custom-select:invalid~.invalid-feedback, .was-validated .custom-select:invalid~.invalid-tooltip, .was-validated .form-control-custom:invalid~.invalid-feedback, .was-validated .form-control-custom:invalid~.invalid-tooltip {
    display: block;
}
.custom-select.is-invalid, .form-control-custom.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control-custom:invalid {
    border-color: #dc3545;
}
